


















































import { defineComponent, PropType } from '@vue/composition-api'
import { IFilterPreset } from '@/modules/marketplace/interfaces'
import { Tooltip } from '@/components/common/Tooltip'
import { ListItem } from '@/components/common/ListItem'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'

export default defineComponent({
  name: 'FilterPresetList',
  components: { Tooltip, ListItem, FloatingMenu },
  props: {
    filters: {
      type: Array as PropType<IFilterPreset[]>,
      required: true,
      default: () => null,
    },
    activePresetId: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const handleClickAction = (action: string, id: string) => {
      emit(action, id)
    }

    return { handleClickAction }
  },
})
