






















import { formatNumber } from '@/utils/utils'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'Range',
  components: { TextField },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
    fractionDigits: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    min: {
      get() {
        return this.value[0]
          ? formatNumber(this.value[0], this.fractionDigits)
          : undefined
      },
      set(value: string) {
        if (value.length === 0) {
          this.$emit('input', [undefined, this.value[1]])
          this.$emit('change')
        } else {
          const min = Number(value.replace(/[^-0-9]/g, ''))

          if (!isNaN(min)) {
            this.$emit('input', [min, this.value[1]])
            this.$emit('change')
          }
        }
      },
    },
    max: {
      get() {
        return this.value[1]
          ? formatNumber(this.value[1], this.fractionDigits)
          : undefined
      },
      set(value: string) {
        if (value.length === 0) {
          this.$emit('input', [this.value[0], undefined])
          this.$emit('change')
        } else {
          const max = Number(value.replace(/[^-0-9]/g, ''))

          if (!isNaN(max)) {
            this.$emit('input', [this.value[0], max])
            this.$emit('change')
          }
        }
      },
    },
  },
}
