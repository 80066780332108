






























































import { PropType } from 'vue'
import { IOffer } from '@/interfaces'
import { RestrictedOrganisationButton } from '@/components/common/RestrictedOrganisationButton'

export default {
  props: {
    offers: {
      type: Array as PropType<IOffer[]>,
      required: true,
    },
    isOrganisationRestricted: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  components: {
    RestrictedOrganisationButton,
  },
  data() {
    return {
      markers: [],
      isGmapVisible: !this.isOrganisationRestricted,
    }
  },
  watch: {
    offers() {
      this.getMarkers()
    },
  },
  created() {
    this.getMarkers()
  },
  methods: {
    getMarkers() {
      const markers = []
      for (const offer of this.offers as IOffer[]) {
        if (offer.organisation === undefined) {
          continue
        }

        const {
          offerType,
          materialType,
          id: offerId,
          sourceStation,
          destinationStation,
        } = offer
        const station =
          sourceStation || destinationStation || (offer as any).station
        const materialTypeCode = materialType.code

        if (!station) {
          return
        }

        const existingMarker = markers.find(
          (marker) =>
            marker.id ===
            `${station.addressPostCode}-${station.addressCountyCode}`
        )

        if (!existingMarker) {
          markers.push({
            id: `${station.addressPostCode}-${station.addressCountyCode}`,
            infoWindowOpen: true,
            offers: [
              {
                id: offerId,
                materialTypeCode,
                offerType,
              },
            ],
            position: station.addressLocation,
          })
        } else {
          existingMarker.offers.push({
            id: offerId,
            materialTypeCode,
            offerType,
          })
        }
      }

      this.markers = markers
    },
    toggleInfoWindow(marker: any) {
      marker.infoWindowOpen = !marker.infoWindowOpen
    },
    toogleGmapVisibility() {
      this.isGmapVisible = !this.isGmapVisible
    },
    onInfoWindowClose(marker: any) {
      marker.infoWindowOpen = false
    },
  },
}
