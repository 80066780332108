import { render, staticRenderFns } from "./OrganisationTags.vue?vue&type=template&id=0ae4eb72&"
import script from "./OrganisationTags.vue?vue&type=script&lang=ts&"
export * from "./OrganisationTags.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports