






































































































































import { PropType } from 'vue'
import CountryCodeSelect from '@/components/common/CountryCodeSelect/CountryCodeSelect.vue'
import ShippingIncludedSelect from '@/components/common/ShippingIncludedSelect.vue'
import OfferStatusSelect from '@/components/common/OfferStatusSelect.vue'
import OfferTypesCheckboxGroup from '@/components/common/OfferTypesCheckboxGroup.vue'
import OrganisationTags from '@/components/common/OrganisationTags/OrganisationTags.vue'
import MaterialFormatGroupsTags from '@/components/common/MaterialFormatGroupsTags/MaterialFormatGroupsTags.vue'
import MaterialTypeTags from '@/components/common/MaterialTypeTags.vue'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import { DatePicker } from '@/components/common/DatePicker'
import Range from '@/components/common/Range/Range.vue'
import PriceRange from '@/components/common/PriceRange/PriceRange.vue'
import PostCode from '@/components/common/PostCode/PostCode.vue'
import { RadiusSelect } from '@/components/common/RadiusSelect'
import { Checkbox } from '@/components/molecules/Checkbox'
import { mapActions, mapGetters } from 'vuex'
import { getDefaultFilters } from '@/modules/marketplace/state'
import { OfferType } from '@/enums'
import { IOffer } from '@/interfaces'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

export default {
  name: 'OfferFilter',
  props: {
    isOrganisationRestricted: Boolean as PropType<boolean>,
    default: () => false,
  },
  components: {
    Checkbox,
    CountryCodeSelect,
    ShippingIncludedSelect,
    OfferTypesCheckboxGroup,
    OfferStatusSelect,
    OrganisationTags,
    MaterialFormatGroupsTags,
    MaterialTypeTags,
    DateRangePicker,
    Range,
    PriceRange,
    DatePicker,
    PostCode,
    RadiusSelect,
  },
  inject: ['enums'],
  data() {
    return {
      defaultFilters: getDefaultFilters(),
      expiresAtPickerOptions: {
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      },
      rules: {
        offerType: {
          required: false,
          trigger: 'blur',
          validator: this.validateOfferType,
        },
        unitAmount: {
          required: false,
          trigger: 'blur',
          validator: (rule: object, value: number[], callback: any) => {
            if (value && value.length === 2 && value[0] >= value[1]) {
              return callback(
                new Error(
                  this.$t('offerFilters.validation.unitAmount.validator')
                )
              )
            }

            return callback()
          },
        },
        unitPrice: {
          required: false,
          trigger: 'blur',
          validator: (rule: object, value: number[], callback: any) => {
            if (value && value.length === 2 && value[0] >= value[1]) {
              return callback(
                new Error(
                  this.$t('offerFilters.validation.unitPrice.validator')
                )
              )
            }

            return callback()
          },
        },
        postCode: {
          required: false,
          trigger: 'blur',
          validator: this.validatePostCode,
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      organisationStatus: 'getOrganisationStatus',
    }),
    ...mapGetters('marketplace', {
      filters: 'getFilters',
      queryFromFilters: 'getQueryFromFilters',
      offers: 'getOffers',
    }),
    showActive() {
      return (
        isEqual(
          omit(this.filters, 'showActive'),
          omit(getDefaultFilters(), 'showActive')
        ) &&
        this.offers?.reduce(
          (total: number, offer: IOffer) => total + (offer.isShadow ? 0 : 1),
          0
        ) < 10
      )
    },
    validateRadius() {
      const len = this.postCodeLength
      return String(this.filters?.postCode).length >= len
    },
    isOneCountrySelected() {
      return (
        Array.isArray(this.filters.countryCode) &&
        this.filters.countryCode.length === 1
      )
    },
    postCodeLength() {
      const code = this.filters?.countryCode[0]
      switch (code) {
        case this.enums.CountryCode.AT:
        case this.enums.CountryCode.CH:
          return 4
        default:
          return 5
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler /* istanbul ignore next */() {
        const { radius } = this.filters

        if (!this.isOneCountrySelected && this.filters.postCode) {
          this.filters.postCode = ''
        }

        if (this.validateRadius) {
          this.filters.radius = radius || 50
        } else if (!this.filters?.postCode) {
          this.filters.radius = null
        }
      },
    },
  },
  async created() {
    await this.setFiltersFromQuery()
  },
  methods: {
    ...mapActions('marketplace', ['fetchOffers', 'setFiltersFromQuery']),
    async updateQuery() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (valid) {
          await this.$router.push({
            query: this.queryFromFilters,
          })
          await this.fetchOffers()
        }
      })
    },
    validateOfferType(rule: object, value: OfferType[], callback: any) {
      if (value.length !== 1) {
        this.filters.isShippingIncluded = undefined
      }

      return callback()
    },
    validatePostCode(rule: object, value: string, callback: any) {
      if (!this.isOneCountrySelected) {
        return callback()
      }

      if (value && String(value).length < this.postCodeLength) {
        return callback(
          new Error(this.$t('offerFilters.validation.postCode.validator'))
        )
      }

      return callback()
    },
  },
}
