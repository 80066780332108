





















































import { PropType } from 'vue'
import { Dialog } from '@/components/molecules/Dialog'
import { Checkbox } from '@/components/molecules/Checkbox'
import { IMaterialFormatGroup, IMaterialType } from '@/interfaces'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'OfferFilterCreate',
  components: { CustomDialog: Dialog, TextField, Checkbox },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    materialTypes: {
      type: Array as PropType<IMaterialType[]>,
      required: true,
    },
    materialFormatGroups: {
      type: Array as PropType<IMaterialFormatGroup[]>,
      required: true,
    },
  },
  data() {
    return {
      isDialogVisible: false,
      formItems: {
        name: '',
        emailNotifications: true,
      },
      rules: {
        name: {
          required: true,
          message: this.$t('offer.createFilterDialog.form.name.required'),
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    filterName() {
      const nameParts = []
      if (this.filters.offerType.length === 1) {
        nameParts.push(
          this.$t(`offerForm.form.offerType.value.${this.filters.offerType[0]}`)
        )
      }
      if (this.filters.materialTypeId.length) {
        nameParts.push(
          this.nameFromCodes(
            this.filters.materialTypeId,
            this.materialTypes.map((v: IMaterialType) => ({
              id: v.id,
              name: v.code,
            }))
          )
        )
      }
      if (this.filters.materialFormatGroupId.length) {
        nameParts.push(
          this.nameFromCodes(
            this.filters.materialFormatGroupId,
            this.materialFormatGroups
          )
        )
      }
      if (
        this.filters.offerType.length === 1 &&
        this.filters.isShippingIncluded !== undefined
      ) {
        const yesNo = this.filters.isShippingIncluded ? 'yes' : 'no'
        nameParts.push(
          this.$t(
            `common.isShippingIncluded.${this.filters.offerType[0]}.${yesNo}`
          )
        )
      }
      if (
        this.filters.unitPrice[0] !== undefined ||
        this.filters.unitPrice[1] !== undefined
      ) {
        nameParts.push(
          `${this.nameFromRange(this.filters.unitPrice, (n: number) => {
            return `${Math.round((n || 0) / 100)}€`
          })}`
        )
      }
      if (
        this.filters.unitAmount[0] !== undefined ||
        this.filters.unitAmount[1] !== undefined
      ) {
        nameParts.push(
          `${this.nameFromRange(
            this.filters.unitAmount,
            (n: number) => `${n}t`
          )}`
        )
      }
      if (this.filters.postCode && this.filters.radius) {
        const placeholder = `${this.$t('offer.filters.addressPostCode')} ${
          this.filters.postCode
        } ${this.$t('common.label.radius')} ${this.filters.radius}km`
        nameParts.push(placeholder)
      }

      return nameParts.join(' - ')
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (valid) {
          this.$emit('submit', this.formItems)
          this.formItems.name = ''
          this.formItems.emailNotifications = true
          this.toggleDialog()
        }
      })
    },
    toggleDialog() {
      if (!this.isDialogVisible) {
        this.formItems.name = this.filterName
      }
      this.isDialogVisible = !this.isDialogVisible
    },
    nameFromRange(
      range: number[],
      numberFormatter: (v: number | null) => string | null
    ) {
      const parts = []
      if (range[0] !== undefined) {
        parts.push(
          `${this.$t('common.from').toLowerCase()} ${numberFormatter(range[0])}`
        )
      }
      if (range[1] !== undefined) {
        parts.push(
          `${this.$t('common.to').toLowerCase()} ${numberFormatter(range[1])}`
        )
      }

      return parts.join(' ')
    },
    nameFromCodes(codes: string[], vocabulary: { id: string; name: string }[]) {
      if (codes.length) {
        const translated: string[] = []
        codes.slice(0, 2).forEach((value: string) => {
          const found = vocabulary.find(({ id }) => id === value)
          if (found) {
            translated.push(found.name)
          }
        })
        let translatedStr = translated.join(', ')
        if (codes.length > 2) {
          translatedStr += ' ' + this.$t('offerFilters.filterPresets.andOther')
        }

        return translatedStr
      }
    },
  },
}
