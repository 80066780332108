

































import { defineComponent, PropType, ref } from '@vue/composition-api'
import HttpService from '@/services/HttpService/HttpService'

export default defineComponent({
  name: 'OfferViews',
  props: {
    viewsCount: {
      type: Number as PropType<number>,
      required: true,
      default: () => 0,
    },
    offerId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const viewers = ref([])
    const loading = ref(false)

    const onClick = async () => {
      if (props.viewsCount > 0 && viewers.value.length === 0) {
        await getOfferViewers()
      }
    }

    const getOfferViewers = async () => {
      loading.value = true
      viewers.value = await HttpService.request({
        path: `/offers/${props.offerId}/views`,
      })
      loading.value = false
    }

    return { onClick, loading, viewers }
  },
})
