var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.mapWrapper},[(!_vm.isOrganisationRestricted)?_c('v-btn',{class:_vm.$style.button,attrs:{"small":""},on:{"click":_vm.toogleGmapVisibility}},[_c('v-icon',{attrs:{"left":!_vm.isGmapVisible}},[_vm._v(" "+_vm._s(_vm.isGmapVisible ? 'mdi-close' : 'mdi-map-marker-outline')+" ")]),(!_vm.isGmapVisible)?_c('span',[_vm._v(_vm._s(_vm.$t('common.map.open')))]):_vm._e()],1):_c('restricted-organisation-button',[_c('v-btn',{attrs:{"slot":"reference","small":""},slot:"reference"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t('common.map.open')))])],1)],1),(_vm.isGmapVisible)?_c('gmap-map',{class:_vm.$style.map,attrs:{"center":{
      lat: 51,
      lng: 10.5,
    },"zoom":6,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},_vm._l((_vm.markers),function(marker){return _c('gmap-marker',{key:marker.id,attrs:{"clickable":true,"position":marker.position},on:{"click":function($event){return _vm.toggleInfoWindow(marker)}}},[_c('gmap-info-window',{attrs:{"opened":marker.infoWindowOpen},on:{"closeclick":function($event){return _vm.onInfoWindowClose(marker)}}},_vm._l((marker.offers),function(offer){return _c('span',{key:offer.id},[_c('router-link',{attrs:{"to":{ name: 'offer', params: { id: offer.id } },"data-offer-type":offer.offerType}},[_vm._v(" "+_vm._s(offer.materialTypeCode)+" ")])],1)}),0)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }