















import moment from 'moment'
import { QUERY_DATE_FORMAT } from '@/modules/marketplace/getters'

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Number,
      default: () => undefined,
    },
    pickerOptions: {
      type: Object,
      default: () => null,
    },
    defaultValue: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    date: {
      get(): Date | null {
        if (!this.value) {
          return null
        }
        return moment.unix(this.value).toDate()
      },
      set(date: Date | null) {
        if (date === null) {
          return this.$emit('input', undefined)
        }
        this.$emit('input', moment(date, QUERY_DATE_FORMAT).unix())
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('change')
    },
  },
}
