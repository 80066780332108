

















import { defineComponent, PropType } from '@vue/composition-api'
import { IOrganisation, IPartnersOrganisations } from '@/interfaces'
import { fetchOrganisations } from '@/utils/OrganisationUtil/OrganisationUtil'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'
import { OrganisationStatus } from '@/enums'

export default defineComponent({
  name: 'OrganisationTags',
  components: { DropdownMulti },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    partnersOrganisations: {
      type: Array as PropType<IPartnersOrganisations[]>,
      default: () => null,
    },
    isPartners: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  data() {
    return {
      isLoading: true,
      allOrganisations: [] as IOrganisation[],
    }
  },
  computed: {
    organisations: {
      get(): Array<string> {
        if (this.isLoading) {
          return []
        }
        return this.value
      },
      set(organisationCodes: Array<string>) {
        this.$emit(
          'input',
          this.allOrganisations
            .filter((organisation: IOrganisation) => {
              return organisationCodes.includes(organisation.id)
            })
            .map((organisation: IOrganisation) => organisation.id)
        )
      },
    },
  },
  watch: {
    partnersOrganisations(newValue) {
      this.allOrganisations = newValue
    },
  },
  async created() {
    if (this.isPartners) {
      this.allOrganisations = this.partnersOrganisations || []
    } else {
      const { organisations } = await fetchOrganisations({
        status: OrganisationStatus.Validated,
        limit: this.$appConfig.organisationsLimit,
      })
      this.allOrganisations = organisations
    }
    this.isLoading = false
    this.$emit('ready', 'organisations')
  },
  methods: {
    change() {
      this.$emit('change')
    },
  },
})
