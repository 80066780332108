









import { PropType } from 'vue'
import { Dropdown } from '@/components/molecules/Dropdown'
import { Radius } from '@/enums'

export default {
  name: 'RadiusSelect',
  components: { Dropdown },
  props: {
    value: {
      type: [String, Number],
      default: () => '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  computed: {
    radius: {
      get(): string | null {
        return (this.value && String(this.value)) || null
      },
      set(radius: string) {
        this.$emit('input', radius)
        this.$emit('change', radius)
      },
    },
    radiusItems() {
      return Object.values(Radius).map((i) => ({
        text: `+${i}km`,
        value: String(i),
      }))
    },
  },
  created() {
    this.$emit('input', this.radius)
  },
}
