































































































































































import { mapActions, mapGetters } from 'vuex'
import OfferForm from '@/components/offer/OfferForm.vue'
import { OfferListItem } from '@/components/offer/OfferListItem'
import OffersMap from '@/components/offer/OffersMap.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import { PageTitle } from '@/components/atoms/PageTitle'
import { Dialog } from '@/components/molecules/Dialog'
import InfoComponent from '@/components/common/InfoComponent/InfoComponent.vue'
import { formatDate } from '@/utils/utils'
import moment from 'moment'
import OfferFilter from '@/components/offer/OfferFilter.vue'
import { FilterPresetList } from '@/components/offer/FilterList'
import { OfferFilterCreateDialog } from '@/components/offer/OfferFilterCreateDialog'
import { IFilterPreset } from '@/modules/marketplace/interfaces'
import { getDefaultFilters } from '@/modules/marketplace/state'
import isEqual from 'lodash/isEqual'
import { IFilterCreate } from '@/modules/marketplace/actions'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'
import { ListItem } from '@/components/common/ListItem'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import BidForm from '@/components/bid/BidForm.vue'
import { RestrictedOrganisationButton } from '@/components/common/RestrictedOrganisationButton'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'
import { IOffer } from '@/interfaces'
import { getDefaultFormItems } from '@/utils/OfferUtil/OfferUtil'

export default {
  name: 'Marketplace',
  components: {
    PageTitle,
    ConfirmDialog,
    InfoComponent,
    OfferListItem,
    OfferForm,
    CustomDialog: Dialog,
    OfferFilter,
    FilterPresetList,
    OfferFilterCreateDialog,
    OffersMap,
    BidForm,
    FloatingMenu,
    RestrictedOrganisationButton,
    ListItem,
    LoadingSpinner,
    LoadMoreButton,
  },
  inject: ['enums'],
  data() {
    return {
      isOfferDialogVisible: false,
      isInfoDialogVisible: false,
      selectedOfferFormItems: getDefaultFormItems(),
      isAsideVisible: false,
      offerContractType: this.enums.OfferContractType.Spot,
      isBidDialogVisible: false,
      selectedOffer: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      organisationStatus: 'getOrganisationStatus',
      isOrganisationRestricted: 'isOrganisationRestricted',
    }),
    ...mapGetters('materialType', {
      materialTypes: 'getMaterialTypes',
    }),
    ...mapGetters('materialFormatGroups', {
      materialFormatGroups: 'getMaterialFormatGroups',
    }),
    ...mapGetters('rating', ['publicRatings', 'partnerRatings']),
    ...mapGetters('marketplace', {
      offers: 'getOffers',
      filters: 'getFilters',
      isLoadingNext: 'getIsLoadingNext',
      isLoading: 'getIsLoading',
      tableDataNext: 'getTableDataNext',
      error: 'getError',
      filterPresets: 'getFilterPresets',
      isLoadingFilters: 'getIsLoadingFilters',
      activeFilterPresetId: 'getActiveFilterPresetId',
    }),
    ...mapGetters('priceIndex', ['priceIndices']),
    mainClass() {
      const contentStyle = [this.$style.content]

      if (this.isAsideVisible) {
        contentStyle.push(this.$style.contentAsideVisible)
      }

      return contentStyle
    },
    organisationValidationLimitDate() {
      return formatDate(
        moment(this.user?.organisation?.createdAt)
          .add(this.$appConfig.organisationValidationLimitDays, 'days')
          .unix()
      )
    },
    hasSelectedFilters() {
      return !isEqual(getDefaultFilters(), this.filters)
    },
    dialogTitle() {
      if (!this.selectedOffer) return ''

      return `${this.$t('bidding.title.create')} ${this.$tc(
        `common.offerType.${this.selectedOffer.offerType}`,
        1
      )}`
    },
  },
  async created() {
    this.fetchFilterPresets()

    await Promise.all([this.getMaterialFormatGroups(), this.loadPriceIndices()])

    if (!this.publicRatings) {
      await this.fetchPublicRatings()
    }

    if (!this.tradingPartners) {
      await this.fetchTradingPartners(this.user?.organisationId)
    }

    this.$recyfyNotificationService(this).subscribe('marketplace')
  },
  destroyed() {
    this.$recyfyNotificationService(this).unsubscribe('marketplace')
  },
  methods: {
    ...mapActions('rating', ['fetchPublicRatings', 'fetchTradingPartners']),
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    ...mapActions('marketplace', [
      'fetchOffers',
      'setFiltersFromQuery',
      'createFilterPreset',
      'fetchFilterPresets',
      'deleteFilterPreset',
      'updateFilterPreset',
      'setActiveFilterPreset',
    ]),
    ...mapActions('auth', ['toggleUserProfileModal']),
    ...mapActions('priceIndex', ['loadPriceIndices']),
    handleNewOfferFloatingMenu(command: string) {
      switch (command) {
        case 'offer':
          this.offerContractType = this.enums.OfferContractType.Spot
          break
        case 'contract':
          this.offerContractType = this.enums.OfferContractType.Recurring
          break
      }

      return this.onAddNewOffer()
    },
    onAddNewOffer() {
      this.toggleUserProfileModal().then(() => {
        if (
          [
            this.enums.OrganisationStatus.Draft,
            this.enums.OrganisationStatus.Ready,
          ].includes(this.organisationStatus)
        ) {
          this.isInfoDialogVisible = true
        } else {
          this.isOfferDialogVisible = true
        }
      })
    },
    onCreateBid(offer: IOffer) {
      this.isBidDialogVisible = true
      this.selectedOffer = offer
    },
    async onNegotiationRedirect(id: string) {
      await this.$router.push({ path: `/offers/${id}/contracts` })
    },
    async onBidSave() {
      await this.fetchOffers()
    },
    closeBidDialog() {
      this.isBidDialogVisible = false
      // setTimeout not required, just to have a smoother transition
      window.setTimeout(() => {
        this.selectedOffer = null
      }, 1000)
    },
    closeOfferDialog() {
      this.isOfferDialogVisible = false
      this.resetOfferFormItems()
    },
    closeInfoDialog() {
      this.isInfoDialogVisible = false
    },
    onInfoDialogCTA() {
      if (this.organisationStatus) {
        if (this.organisationStatus === this.enums.OrganisationStatus.Draft) {
          this.$router.push({
            name: 'organisation',
            params: { id: this.user?.organisationId },
          })
        } else if (
          this.organisationStatus === this.enums.OrganisationStatus.Ready
        ) {
          this.closeInfoDialog()
        }
      }
    },
    resetOfferFormItems() {
      this.selectedOfferFormItems = getDefaultFormItems()
    },
    async onResetFilters() {
      await this.$router.replace({ query: {} })
      await this.setActiveFilterPreset(null)
      await this.setFiltersFromQuery()
    },
    async onCreateFilterPreset(filter: IFilterCreate) {
      await this.createFilterPreset(filter)
    },
    async onDeleteFilterPreset(id: string) {
      await this.deleteFilterPreset(id)
    },
    async onToggleSubscription(id: string) {
      const preset = this.filterPresets.find(
        (preset: IFilterPreset) => preset?.id === id
      )

      if (preset) {
        await this.updateFilterPreset({
          ...preset,
          emailNotifications: !preset.emailNotifications,
        })
      }
    },
    async onFilterSelect(id: string) {
      const preset = this.filterPresets.find(
        (preset: IFilterPreset) => preset?.id === id
      )
      this.setActiveFilterPreset(preset?.id)
    },
    getPartnerRatings(organisationId: string) {
      return this.partnerRatings?.[organisationId]
    },
    getPublicRatings(organisationId: string) {
      return this.publicRatings?.[organisationId]
    },
  },
}
