









import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'Range',
  components: { TextField },
  props: {
    value: {
      type: [String, Number],
      required: true,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    code: {
      get() {
        return Array.isArray(this.value) ? this.value.split(',')[1] : this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
  },
}
