
















import { mapActions, mapGetters } from 'vuex'
import { IMaterialFormatGroup } from '@/interfaces'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'

export default {
  name: 'MaterialFormatGroupsTags',
  components: { DropdownMulti },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('materialFormatGroups', {
      isLoading: 'getIsLoading',
      allMaterialFormatGroups: 'getMaterialFormatGroups',
    }),
    materialFormatGroups: {
      get(): Array<string> {
        if (this.isLoading) {
          return []
        }

        return this.value
      },
      set(materialFormatGroupIds: Array<string>) {
        this.$emit(
          'input',
          this.allMaterialFormatGroups
            .filter((materialFormatGroup: IMaterialFormatGroup) => {
              return materialFormatGroupIds.includes(materialFormatGroup.id)
            })
            .map(
              (materialFormatGroup: IMaterialFormatGroup) =>
                materialFormatGroup.id
            )
        )
      },
    },
  },
  async created() {
    await this.getMaterialFormatGroups()
    this.materialFormatGroups = this.allMaterialFormatGroups
      .filter((format: IMaterialFormatGroup) => {
        return this.value.includes(format.id)
      })
      .map((format: IMaterialFormatGroup) => format.id)
    this.$emit('ready', 'materialFormatGroups')
  },
  methods: {
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    change() {
      this.$emit('change')
    },
  },
}
