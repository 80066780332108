

























import { defineComponent, PropType } from '@vue/composition-api'
import { formatDateFromNow } from '@/utils/utils'
import { IOffersBidder } from '@/interfaces'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'

export default defineComponent({
  components: { MarkdownComponent },
  props: {
    bidders: {
      type: Array as PropType<IOffersBidder[]>,
      default: () => null,
    },
  },
  setup() {
    const getBidderEntry = ({ user, organisation, bid }: IOffersBidder) => {
      const entry = [
        `${user.firstName} ${user.lastName}`,
        `<a href="/organisations/${organisation.id}">${organisation.name}</a>`,
        `${formatDateFromNow(bid.createdAt)}`,
      ]

      return entry.join(' | ')
    }

    return { getBidderEntry }
  },
})
