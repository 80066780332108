






















import { TextField } from '@/components/molecules/TextField'
import { formatNumber } from '@/utils/utils'

export default {
  name: 'PriceRange',
  components: { TextField },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
    fractionDigits: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rawMin: '',
      rawMax: '',
    }
  },
  computed: {
    min: {
      get() {
        if (isNaN(Number(this.rawMin))) {
          return this.rawMin
        }

        return this.value[0]
          ? formatNumber(this.value[0] * 0.01, this.fractionDigits)
          : undefined
      },
      set(value: string) {
        this.rawMin = value.replace(/[^-0-9]/g, '')

        if (value.length === 0) {
          this.$emit('input', [undefined, this.value[1]])
          this.$emit('change')
        } else if (this.rawMin.length > 0) {
          const min = Number(this.rawMin)

          if (!isNaN(min)) {
            this.$emit('input', [min * 100, this.value[1]])
            this.$emit('change')
          }
        }
      },
    },
    max: {
      get() {
        if (isNaN(Number(this.rawMax))) {
          return this.rawMax
        }

        return this.value[1]
          ? formatNumber(this.value[1] * 0.01, this.fractionDigits)
          : undefined
      },
      set(value: string) {
        this.rawMax = value.replace(/[^-0-9]/g, '')

        if (value.length === 0) {
          this.$emit('input', [this.value[0], undefined])
          this.$emit('change')
        } else if (this.rawMax.length > 0) {
          const max = Number(this.rawMax)

          if (!isNaN(max)) {
            this.$emit('input', [this.value[0], max * 100])
            this.$emit('change')
          }
        }
      },
    },
  },
}
