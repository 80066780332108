






import { PropType } from 'vue'
import { formatDateFromNow } from '@/utils/utils'

export default {
  name: 'DateFromNow',
  props: {
    date: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  data() {
    return {
      intervalDate: null,
    }
  },
  created() {
    setInterval(() => {
      this.intervalDate = formatDateFromNow(this.date)
    }, 6e4)
  },
  beforeDestroy() {
    clearInterval(this.intervalDate)
  },
  methods: {
    formatDateFromNow,
  },
}
