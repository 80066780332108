




































































































































































































































































import { PropType } from 'vue'
import moment from 'moment'
import {
  IOffer,
  IPublicRating,
  IRelationPartner,
  IUser,
  IOffersBidder,
} from '@/interfaces'
import { currencyPerUnitType, formatDateTime } from '@/utils/utils'
import Thumbnail from '@/components/common/Thumbnail.vue'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { getOfferImageUrls } from '@/utils/AssetUtil/AssetUtil'
import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import { RatingTabsPopover } from '@/components/common/Rating/RatingTabsPopover'
import { OfferViews } from '@/components/common/OfferViews'
import DateFromNow from '@/components/common/DateFromNow.vue'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import OfferTypeBadge from '@/components/common/OfferTypeBadge.vue'
import { OfferStatusMixin } from '@/mixins/OfferStatusMixin'
import { BiddersPopover } from '@/components/offer/BiddersPopover'
import { RestrictedOrganisationButton } from '@/components/common/RestrictedOrganisationButton'
import { Tooltip } from '@/components/common/Tooltip'
import { Emitter, OrganisationStatus, TooltipPlacement } from '@/enums'

export default {
  name: 'OfferListItem',
  components: {
    Thumbnail,
    OrganisationAvatar,
    DateFromNow,
    OfferTypeBadge,
    RatingTabsPopover,
    OfferViews,
    BiddersPopover,
    RestrictedOrganisationButton,
    Tooltip,
  },
  mixins: [ShippingMixin, PaymentConditionMixin, OfferStatusMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
      default: () => null,
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
    publicRatings: {
      type: Object as PropType<IPublicRating>,
      required: false,
      default: () => null,
    },
    partnerRatings: {
      type: Object as PropType<IRelationPartner>,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      moment,
      TooltipPlacement,
    }
  },
  computed: {
    isShadowOffer() {
      return this.offer.isShadow
    },
    expiresAt() {
      return this.$t('offer.offersTable.offerValidUntil', {
        date: formatDateTime(this.offer.expiresAt),
      })
    },
    currencyPerUnitType() {
      return currencyPerUnitType(this.offer.material.unitType)
    },
    offerFirstImageUrl() {
      const urls = getOfferImageUrls(this.offer)

      return urls.length ? urls[0] : null
    },
    createdBy() {
      return this.$t('common.createdBy', {
        creator: `${this.offer?.user?.firstName} ${this.offer?.user?.lastName}`,
      })
    },
    isContractTypeRecurring() {
      return this.offer.contractType === this.enums.OfferContractType.Recurring
    },
    isOfferOwner() {
      return this.offer?.organisationId === this.user?.organisationId
    },
    isNegotiationParticipant() {
      return this.offer?.bidders?.find(
        (bidder: IOffersBidder) =>
          bidder?.organisation?.id === this.user?.organisationId
      )
    },
    isOfferAnonymous() {
      return this.offer?.isAnonymous || this.isOrganisationRestricted
    },
    offerStation() {
      return this.offer.sourceStation || this.offer.destinationStation
    },
    hasRatings() {
      return (
        !this.offer?.isAnonymous && (this.publicRatings || this.partnerRatings)
      )
    },
    isGlobalAdmin() {
      return this.user?.isGlobalAdmin
    },
    isOrganisationRestricted() {
      return (
        this.user?.organisation?.organisationStatus ===
        OrganisationStatus.Restricted
      )
    },
  },
  methods: {
    onClick(offer: IOffer) {
      this.$emit('create', offer)
    },
    onRedirectToNegotiation(id: string) {
      this.$emit('negotiation', id)
    },
    onItemClick() {
      if (!this.isOrganisationRestricted) return

      this.$emitter.emit(Emitter.Restricted)
    },
  },
}
